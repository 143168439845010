import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';

let user=null;
try {
    const queryString = window.location.href.split('?')[1]; // 这会获取到 "user=8202"
    user = new URLSearchParams(queryString).get('user');
} catch (err) {
    console.log(err)
}

const HeaderBanner = () => {
    return (
        <div className="static-slider-head">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" md="6" className="align-self-center text-center">
                        <h1 className="title">Weird Drink Laboratory</h1>
                        <h4 className="subtitle font-light">奇怪饮料实验室</h4>
                        {user === '4202' && <Link to="https://weirddrinklaboratory-1301964457.cos.ap-shanghai.myqcloud.com/lego-little-prince-extend.pdf" className="btn btn-md m-t-30 btn-info-gradiant font-14">
                            下载图纸
                        </Link>}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
