import React from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

const WDLDescription = (props) => {
    return (
        <div className="spacer">
            <Container>
                <Row className="justify-content-center">
                    <Col md="7" className="text-center">
                        <h1 className="title font-bold">奇怪饮料实验室</h1>
                        <h6 className="subtitle">追逐一场儿时梦想的旅行</h6>
                        <h6 className="subtitle">鼓起勇气面对一天沉重的工作</h6>
                        <h6 className="subtitle">又或者只是尝试一份 <b className="font-bold">奇怪的饮料</b></h6>
                        <h6 className="subtitle">都是一次又一次酣畅淋漓的冒险</h6>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WDLDescription;
