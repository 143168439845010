import React, {useState} from "react";
import PropTypes from "prop-types";

// core components
import HeaderBanner from "../../components/banner/banner.jsx";

// sections for this page
import WDLDescription from "./sections/description";
import MenuList from "../wdlpage/sections/menulist";
import DonateSection from "./sections/donate-section";
import WDLContact from "./sections/contact";
import OrderFirst20240611 from "./sections/wdlmodal";
import WDLModal from "./sections/wdlmodal";
import {
    DrinkNamePlaceholder,
    ModalIDs,
    FirstOrderModalItems,
    DonateModalItem,
    randomDonateModalImage
} from "./constants";
import {Alert} from "reactstrap";
import {orderRecord, uploadLifeFragment} from "./requests/request";

const WDLHome = () => {
    const [currentModalItem, setCurrentModalItem] = useState(null);
    const [orderDrinkItem, setOrderDrinkItem] = useState(null);
    const [toastItem, setToastItem] = useState(null);
    const [fileSelectItem, setFileSelectItem] = useState({selectFile: (file) => {console.log(file)}});
    const [donateModalItem, setDonateModalItem] = useState(null);

    const fileInput = React.createRef();
    const handleFileChange = (event) => {
        console.log(event)
        fileSelectItem.selectFile(event.target.files[0])
    }

    const selectDrinkItem = (drinkItem) => {
        if(orderDrinkItem) {
            console.log('> orderDrinkItem:', orderDrinkItem);
            toast('您已下单啦，如需变更，请联系管理员')
            return;
        }

        console.log('> selectDrinkItem:', drinkItem);

        //设置点单
        setCurrentModalItem({
            ...FirstOrderModalItems[0],
            drinkItem: drinkItem,
        });
    }

    const orderDrink = (drinkItem) => {
        console.log('> orderDrink:', drinkItem);

        return orderRecord(drinkItem.drinkName);
    }

    const selectFile = () => {
        console.log('> selectFile');

        return new Promise((resolve, reject) => {
            setFileSelectItem({selectFile: (file) => {
                    uploadLifeFragment(file).then(() => {
                        resolve(true)
                    }).catch(err => {
                        reject(err)
                    })
                }})
            // 通过程序触发文件选择器的点击事件
            fileInput.current.click();
        })
    }

    const toast = (message) => {
        console.error(message)
        setToastItem({message: message, color: 'danger'})
        setTimeout(()=> {
            toastFade()
        }, 2000)
    }

    const toastSuccess =  (message) => {
        console.log(message)
        setToastItem({message: message, color: 'success'})
    }

    const modalConfirm = () => {
        console.log('> modalConfirm:', currentModalItem);

        if(!currentModalItem.confirm || !currentModalItem.confirm.id) {
            toast('页面出错啦，请联系管理员');
            return;
        }

        if(currentModalItem.confirm.id === ModalIDs.order) {
            orderDrink(currentModalItem.drinkItem)
                .then(() => {
                    setOrderDrinkItem(currentModalItem.drinkItem)
                    switchModalItem(ModalIDs.orderFinish)
                })
                .catch(err => {
                    toast(err.message)
                })
            return;
        }

        if(currentModalItem.confirm.id === ModalIDs.select) {
            selectFile().then(() => {
                switchModalItem(ModalIDs.donateFinish)
            }).catch(err => {
                toast(err.message)
            })
            return;
        }

        if(currentModalItem.confirm.id === ModalIDs.close) {
            setCurrentModalItem(null);
            return;
        }

        switchModalItem(currentModalItem.confirm.id)
    }

    const switchModalItem = (modalId) => {
        console.log('> switchModalItem.modalId:', modalId);

        const nextItems = FirstOrderModalItems.filter(item => item.id === modalId);
        if(nextItems.length > 0) {
            const nextItem = nextItems[0];
            console.log('> nextItem:', nextItem);
            setCurrentModalItem(nextItem);
            return;
        }

        toast('页面出错啦，请联系管理员');
    }

    const modalBack = () => {
        console.log('> modalBack:', currentModalItem);

        if(!currentModalItem.back || !currentModalItem.back.id) {
            toast('页面出错啦，请联系管理员')
            return;
        }

        switchModalItem(currentModalItem.back.id)
    }

    const modalClose = () => {
        console.log('> modalClose:', currentModalItem);

        if(!currentModalItem.close || !currentModalItem.close.id) {
            toast('页面出错啦，请联系管理员')
            return;
        }

        if(currentModalItem.close.id === ModalIDs.close) {
            setCurrentModalItem(null);
            return;
        }

        switchModalItem(currentModalItem.close.id)
    }

    const openFileSelector = () => {
        setFileSelectItem( {
            selectFile: (file) => {
                uploadLifeFragment(file).then(() => {
                    setDonateModalItem({
                        ...DonateModalItem,
                        src: randomDonateModalImage()
                    })
                }).catch(err => {
                    toast('上传失败')
                })
            }
        })
        // 通过程序触发文件选择器的点击事件
        fileInput.current.click();
    };

    const adjustedCurrentModalItem = () => {
        if(!currentModalItem) {
            return null;
        }

        if(!currentModalItem.drinkItem) {
            return currentModalItem;
        }

        return {
            ...currentModalItem,
            content: currentModalItem.content.replace(DrinkNamePlaceholder, currentModalItem.drinkItem.drinkName),
            src: currentModalItem.drinkItem.src,
        }
    }

    const toastFade = () => {
        setToastItem(null)
    }

    const donateModalConfirm = () => {
        openFileSelector()
    }

    const donateModalClose = () => {
        setDonateModalItem(null)
    }

    return (
        <div id="main-wrapper">
            <div className="page-wrapper">
                <div className="container-fluid">
                    {/*<WDLDescription />*/}
                    <HeaderBanner />
                    {/*<MenuList*/}
                    {/*    selectDrinkItem={selectDrinkItem}/>*/}
                    <DonateSection
                        selectFile={openFileSelector.bind(null)}
                    />
                    {/*<WDLModal*/}
                    {/*    modalItem = {adjustedCurrentModalItem()}*/}
                    {/*    modalConfirm={modalConfirm}*/}
                    {/*    modalBack={modalBack}*/}
                    {/*    modalClose={modalClose}*/}
                    {/*/>*/}
                    <WDLModal
                        modalItem = {donateModalItem}
                        modalConfirm={donateModalConfirm}
                        modalClose={donateModalClose}
                    />
                    {/*<WDLContact />*/}
                    <Alert
                        style={{
                        position: 'fixed',
                        top: '10px',
                        right: '10px',
                        zIndex: 9999,
                    }}
                        color={toastItem?.color ?? "danger"}
                        isOpen={!!toastItem}
                        toggle={toastFade}
                    >
                        {toastItem?.message}
                    </Alert>
                </div>
            </div>
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={handleFileChange}
            />
        </div>
    );
}

WDLHome.propTypes = {
    classes: PropTypes.object
};

export default WDLHome;
