/* eslint-disable */
import React from 'react';
import { Row, Col, Container } from 'reactstrap';

const DonateSection = ({ selectFile }) => {
    return (
        <div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">赞助WDL小站</h1>
                            <h6 className="subtitle"><br/>总会有一些精彩的生活碎片 📷<br/>照片、视频、音乐、念想 💬<br/>它见证了<b className="font-bold">浪漫生活</b>，却没机会和他人分享 😮‍💨<br/>欢迎把它们<b className="font-bold">赞助给WDL小站</b> 🎉<br/>～<br/>  小站将不定期开展用户回馈活动🎖️</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="static-slider10">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6" className="align-self-center text-center">
                            <span className="label label-rounded label-inverse">Donate To Us</span>
                            <h1 className="title">精彩生活片段</h1>
                            <h6 className="subtitle op-8">上传媒体文件，分享生活片段</h6>
                            <a className="btn btn-outline-light btn-rounded btn-md btn-arrow m-t-20" data-toggle="collapse"onClick={selectFile}><span>我要赞助 <i className="ti-arrow-right"></i></span></a>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*<div className="static-slider3">*/}
            {/*    <Container>*/}
            {/*        <Row className="justify-content-center">*/}
            {/*            <Col md="8" className="align-self-center text-center">*/}
            {/*                <h1 className="title">也可以尝试分享感想，文摘。 <b className="font-bold">文字有独特的力量。</b> <span className="text-success-gradiant font-bold typewrite" data-period="2000" data-type='[ "Photoshop", "Web Application", "Web Designing", "Web Development" ]'></span></h1>*/}
            {/*                <a className="btn btn-success-gradiant btn-md btn-arrow m-t-20" data-toggle="collapse"><span>文字记录 <i className="ti-arrow-right"></i></span></a>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}
        </div>
    );
}

export default DonateSection;
