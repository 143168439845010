
import photoBalishui from '../../assets/images/wdlmodal/photo-balishui.jpg';
import photoLemo from '../../assets/images/wdlmodal/photo-lemon.jpg';
import photoBaixiangguo from '../../assets/images/wdlmodal/photo-baixiangguo.JPG';

import imageConfirm from '../../assets/images/wdlmodal/iamge-order-confirm.png'
import imageDonateEntry from '../../assets/images/wdlmodal/image-donate-entry.png'
import imageDonateInvite2 from '../../assets/images/wdlmodal/image-donate-invite2.png'
import imageDonateUpload from '../../assets/images/wdlmodal/image-donate-upload.png'
import imageDonateFirstTime from '../../assets/images/wdlmodal/image-donate-firsttime.png'
import imageReject from '../../assets/images/wdlmodal/image-reject-like.png'
import imageWait from '../../assets/images/wdlmodal/image-wait-drink.png'
import imageReward from '../../assets/images/wdlmodal/image-small-reward.png'

import image1 from '../../assets/images/wdlmodal/image1.png'
import image2 from '../../assets/images/wdlmodal/image2.png'
import image3 from '../../assets/images/wdlmodal/image3.png'
import image4 from '../../assets/images/wdlmodal/image4.png'
import image5 from '../../assets/images/wdlmodal/image5.png'
import image6 from '../../assets/images/wdlmodal/image6.png'
import image7 from '../../assets/images/wdlmodal/image7.png'
import image8 from '../../assets/images/wdlmodal/image8.png'
import image9 from '../../assets/images/wdlmodal/image9.png'

export const ModalIDs = {
    close: 'close',
    order: 'order',
    select: 'select',
    orderFinish: 'order-finish',
    donateFinish: 'donate-finish-upload',
};
export const DonateModalItem =
{
    src: null,
    content: `上传成功～感谢！`,
    close: {
        description: '关闭',
    },
    confirm: {
        description: '继续赞助',
    },
}

export const randomDonateModalImage = () => {
    const random = Math.floor(Math.random() * 9)
    return [image1,image2,image3,image4,image5,image6,image7,image8,image9][random]
}

export const DrinkNamePlaceholder = 'drink-name-placeholder;'
export const FirstOrderModalItems = [
    {
        id: 'order-confirm',
        src: null,
        content: `您下单的饮品为【${DrinkNamePlaceholder}】\n制作周期为0~2天\n点击【确定】完成下单`,
        close: {
            description: '我再想想',
            id: ModalIDs.close,
        },
        confirm: {
            description: '确定！',
            id: ModalIDs.order,
        },
    },
    {
        id: ModalIDs.orderFinish,
        src: imageConfirm,
        content: '下单成功！请点击下一步',
        confirm: {
            description: '下一步',
            id: 'donate-invite',
        },
    },
    {
        id: 'donate-invite',
        src: imageDonateInvite2,
        content: '邀请您赞助我们的小站！',
        confirm: {
            description: '怎么赞助？',
            id: 'donate-description',
        },
    },
    {
        id: 'donate-description',
        src: imageDonateUpload,
        content: '赞助方式为上传一张您喜欢的照片',
        close: {
            description: '我拒绝',
            id: 'donate-reject',
        },
        confirm: {
            description: '上传照片！',
            id: ModalIDs.select,
        },
    },
    {
        id: 'donate-reject',
        src: imageReject,
        content: '我们学会了拒绝他人，这是一项很棒的品质～\n您仍可以选择未来再来赞助小站。',
        back: {
            description: '上一步',
            id: 'donate-description',
        },
        confirm: {
            description: '嗯呐～',
            id: 'donate-tip-entry',
        }
    },
    {
        id: ModalIDs.donateFinish,
        src: imageDonateFirstTime,
        content: '哇！感谢好心人，完成了对小站的首次赞助～',
        confirm: {
            description: '下一步',
            id: 'donate-tip-entry',
        }
    },
    {
        id: 'donate-tip-entry',
        src: imageDonateEntry,
        content: '赞助入口在页面底部，会长期开放🔖\n' +
            '\n' +
            '📍📍📍\n' +
            '平时会有一些有意义的照片📷\n' +
            '没机会与他人分享😮‍💨\n' +
            '欢迎把这些照片赞助给小站🫡\n' +
            '小站会不定期的进行用户回馈活动🎖️',
        confirm: {
            description: '下一步',
            id: 'reward-tip-entry',
        }
    },
    {
        id: 'reward-tip-entry',
        src: imageReward,
        content: '首次点单还有小礼品，拿到饮品后可以前往页面顶部查看～',
        confirm: {
            description: '好的呢',
            id: 'summary',
        }
    },
    {
        id: 'summary',
        src: imageWait,
        content: '好啦，至今为止您已完成：\n' +
            '📍 一次下单\n' +
            '📍 一次赞助\n' +
            '\n' +
            '感谢您的光临，请耐心等待饮品制作...',
        confirm: {
            description: '完成',
            id: ModalIDs.close,
        }
    }
];

export const DrinkItems = [
        {
            drinkId: 'drink1',
            src: photoBalishui,
            drinkName: '巴黎水+冰格瑞',
            description: '奇妙的味道，甜甜的，劲劲儿的',
        },
        {
            drinkId: 'drink2',
            src: photoLemo,
            drinkName: '柠檬+咖啡粉',
            description: '如果风险越大，那风险就一定越大',
        },
        {
            drinkId: 'drink3',
            src: photoBaixiangguo,
            drinkName: '百香果+百香果',
            description: '不够栓，但为什么一定要栓',
        },
    ]
