import React, { useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Container,
    Row,
    Col
} from 'reactstrap';

const WDLModal = ({modalItem, modalConfirm, modalBack, modalClose}) => {
    return (
        <div>
            <Container>
                <Row className="m-b-40">
                    <Col md="6" className='d-flex flex-column'>
                        {modalItem && <Modal size="md" isOpen={!!modalItem} toggle={()=>{}}>
                            {/*<ModalHeader toggle={toggle.bind(null)}></ModalHeader>*/}
                            {modalItem.src &&
                                <img
                                style={{"border-radius": "8px"}}
                                src={modalItem.src} alt="img" className="img-responsive" width="200" />}
                            <ModalBody>
                                {modalItem.content.split('\n').map((line, index, array) =>
                                    <React.Fragment key={index}>
                                        {line}
                                        {index < array.length - 1 ? <br /> : null}
                                    </React.Fragment>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                {modalItem.close && <Button color="secondary" onClick={modalClose.bind(null)}>{modalItem.close.description}</Button>}
                                {modalItem.back && <Button color="secondary" onClick={modalBack.bind(null)}>{modalItem.back.description}</Button>}
                                {modalItem.confirm && <Button color="primary" onClick={modalConfirm.bind(null)}>{modalItem.confirm.description}</Button>}
                            </ModalFooter>
                        </Modal>}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WDLModal;
