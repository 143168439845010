import COS from 'cos-js-sdk-v5';
//SecretId:AKIDb4fKq9GOQH2d1B92jVHp9LPHvMfrgR4a
//SecretKey:RgnlX8ayLYhormYBdw76uWzLWaVGpFVa

//TODO:process env
const process_env = {
    SecretId: 'AKIDb4fKq9GOQH2d1B92jVHp9LPHvMfrgR4a',
    SecretKey: 'RgnlX8ayLYhormYBdw76uWzLWaVGpFVa',
}

const Bucket = 'cos-1301964457';
const Region = 'ap-shanghai';

const cos = new COS({
    SecretId: process_env.SecretId,
    SecretKey: process_env.SecretKey,
});

let user=null;
try {
    const queryString = window.location.href.split('?')[1]; // 这会获取到 "user=8202"
    user = new URLSearchParams(queryString).get('user');
} catch (err) {
    console.log(err)
}

function _uploadFile (key, body) {
    // //TODO: 临时代码
    // return new Promise((resolve ,reject) => {
    //     // reject(new Error('请求失败了'))
    //     resolve(true)
    // })
    return cos.uploadFile({
        Bucket: Bucket, /* 填入您自己的存储桶，必须字段 */
        Region: Region,  /* 存储桶所在地域，例如 ap-beijing，必须字段 */
        Key: key,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        Body: body,                /* 必须 */
        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
        onTaskReady: function(taskId) {                   /* 非必须 */
            console.log(taskId);
        },
        onProgress: function (progressData) {           /* 非必须 */
            console.log(JSON.stringify(progressData));
        },
        onFileFinish: function (err, data, options) {   /* 非必须 */
            console.log(options.Key + '上传' + (err ? '失败' : '完成'));
        },
        // 支持自定义headers 非必须
        Headers: {
            'x-cos-meta-test': 123
        },
    });
}

export function uploadLifeFragment (file) {
    return _uploadFile(`${user}-file-${file.name}`, file)
}

export function orderRecord (drinkName) {
    return _uploadFile(`${user}-order-${drinkName}`, '')
}
